@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply bg-neutral-800 text-neutral-300 selection:bg-green-500 selection:text-green-900;
}

.not-authed {
  h1 {
    @apply mb-2 font-serif text-4xl font-extrabold tracking-widest;
  }

  h2 {
    @apply mb-4 font-serif text-2xl font-bold;
  }

  h3 {
    @apply mb-2 font-serif text-xl font-bold;
  }

  section {
    @apply mb-8;
  }

  p {
    @apply mb-2 leading-snug;
  }

  blockquote {
    @apply border-l-4 border-blue-300 pl-4 italic;
  }

  ul {
    @apply ml-2;
  }

  li {
    @apply mb-2 leading-snug;
  }

  .link {
    @apply font-bold text-yellow-500;
  }

  .callout {
    @apply font-bold text-blue-300;
  }
}
